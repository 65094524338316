import React, { Suspense, useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import { AuthProvider } from './hooks/auth/useAuth';
import { LanguageProvider } from './hooks/useLanguage';
import { UserNavigationProvider } from './hooks/useUserNavigation';
import { ToastContainer, Flip, toast } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-photo-view/dist/react-photo-view.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { PAGE_URLS } from './utils/constants';
import OnlineStatusToast from './components/utils/OnlineStatusToast';
import ScrollToTopOnMount from './components/utils/ScrollToTopOnMount';
import SuspenseFallback from './components/layout/suspense-fallback/SuspenseFallback';

// pages
const SignInPage = React.lazy(() => import('./pages/auth/SignInPage'));
const AdminPages = React.lazy(() => import('./pages/admin/AdminPages'));
const SettingsPages = React.lazy(() => import('./pages/settings/SettingsPage'));
const MainPages = React.lazy(() => import('./pages/main/MainPages'));
const SignUpPage = React.lazy(() => import('./pages/auth/SignUpPage'));
const ForgotPasswordPage = React.lazy(() =>
  import('./pages/auth/ForgotPasswordPage')
);
const VerifyEmail = React.lazy(() => import('./pages/auth/VerifyEmail'));
const VerifyMobileNumber = React.lazy(() =>
  import('./pages/auth/VerifyMobileNumber')
);
const AddNewEmail = React.lazy(() => import('./pages/auth/AddNewEmail'));
const AddNewMobile = React.lazy(() => import('./pages/auth/AddNewMobile'));

const HomePage = React.lazy(() => import('./pages/public/HomePage'));
const AppPrivacyPolicyPage = React.lazy(() =>
  import('./pages/public/AppPrivacyPolicyPage')
);
const AccountDeletionRequestPage = React.lazy(() =>
  import('./pages/public/AccountDeletionRequestPage')
);
const EmergCommunitySupport = React.lazy(() =>
  import('./pages/public/EmergCommunitySupport')
);

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <AuthProvider>
            <LanguageProvider>
              <UserNavigationProvider>
                <div className="app">
                  <ScrollToTopOnMount />
                  <OnlineStatusToast />
                  <div className="content">
                    <Suspense fallback={<SuspenseFallback />}>
                      <Routes>
                        <Route path="/*" element={<MainPages />} />
                        <Route
                          path={`${PAGE_URLS.ADMIN}/*`}
                          element={<AdminPages />}
                        />
                        <Route
                          path={`${PAGE_URLS.SETTINGS}/*`}
                          element={<SettingsPages />}
                        />
                        <Route
                          path={PAGE_URLS.SIGNIN}
                          element={<SignInPage />}
                        />
                        <Route
                          path={PAGE_URLS.SIGNUP}
                          element={<SignUpPage />}
                        />
                        <Route
                          path={PAGE_URLS.VERIFY_EMAIL}
                          element={<VerifyEmail />}
                        />
                        <Route
                          path={PAGE_URLS.ADD_EMAIL}
                          element={<AddNewEmail />}
                        />
                        <Route
                          path={PAGE_URLS.ADD_MOBILE_NUM}
                          element={<AddNewMobile />}
                        />
                        <Route
                          path={PAGE_URLS.VERIFY_MOBILE_NUM}
                          element={<VerifyMobileNumber />}
                        />
                        <Route
                          path={PAGE_URLS.FORGOT_PASSWORD}
                          element={<ForgotPasswordPage />}
                        />
                        <Route path={PAGE_URLS.HOME} element={<HomePage />} />
                        <Route
                          path={PAGE_URLS.APP_PRIVACY_POLICY}
                          element={<AppPrivacyPolicyPage />}
                        />
                        <Route
                          path={PAGE_URLS.ACCOUNT_DELETION_REQUEST}
                          element={<AccountDeletionRequestPage />}
                        />
                        <Route
                          path={PAGE_URLS.EMERGENCY_COMMUNITY_SUPPORT}
                          element={<EmergCommunitySupport />}
                        />
                      </Routes>
                    </Suspense>
                    <ToastContainer limit={3} transition={Flip} />
                  </div>
                </div>
              </UserNavigationProvider>
            </LanguageProvider>
          </AuthProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
